<template>
	<div>
		<base-header class="header pt-6 pb-5 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-gradient-red opacity-8"></span>
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-sm-12"></div>
				</div>
			</div>
		</base-header>
		<div class="container-fluid mt-5'">
			<div class="mt-4 mx-4 d-flex align-items-center justify-content-between">
				<div>
					<h2>Settings</h2>
				</div>
				<div class="">
					<button class="btn btn-primary  px-4" @click.prevent="edit=true">Edit</button>
                    <button v-if="edit" class="btn btn-danger  px-3" @click.prevent="edit=false">Cancel</button>

				</div>
			</div>

			<div class="mt-5 p-4 card">
				<div class="row">
					<div class="col-6">
						<h2>Mobile Number</h2>
					</div>
					<div class="col-6">
						<div class="d-flex justify-content-start">
							<p>Current Number</p>
							<h2 class="ml-3">+{{ details.phone }}</h2>
						</div>
						<div v-if="edit" class="d-flex justify-content-start mt-3">
							<p>New</p>
							<input
								placeholder="Mobile Number"
								class="form-control ml-5"
								alternative=""
								v-model="data.phone"
							/>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-6">
						<h2>Whatsapp Number</h2>
					</div>
					<div class="col-6">
						<div class="d-flex justify-content-start">
							<p>Current Number</p>
							<h2 class="ml-3">+{{ details.whatsapp_number }}</h2>
						</div>
						<div v-if="edit" class="d-flex justify-content-start mt-3">
							<p>New</p>
							<input
								placeholder="Whatsapp Number"
								class="form-control ml-5"
								alternative=""
								v-model="data.whatsapp_number"
							/>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-end mt-5" >
					<button
					v-if="edit"
						class="btn btn-success mt-3"
						@click.prevent="handleSave"
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "settings",
	data() {
        return {
            edit: false,
			data: {
				phone: "",
				whatsapp_number: "",
			},
		};
	},
	computed: {
		details() {
			return this.$store.state.Settings.contactDetails;
		},
	},
	methods: {
		fetchContactDetails() {
			this.$store.dispatch("fetchContactDetails").catch((err) => {
				this.$notify({
					title: "Failed to fetch contact details",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},

		handleSave() {
			if (this.data.phone === "") {
				delete this.data.phone;
			} else if (this.data.whatsapp_number === "") {
				delete this.data.whatsapp_number;
			}

			this.$store
				.dispatch("setSettingsDetails", this.data)
				.then(() => {
					this.$notify({
						title: "Updated successfully",
						icon: "fa fa-check",
						type: "success",
					});
					this.fetchContactDetails();
					this.data = {
						phone: "",
						whatsapp_number: "",
                    };
                    this.edit = false;
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to add user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		},
	},
	mounted() {
		this.fetchContactDetails();
	},
};
</script>
<style scoped>
.bg-dark {
	background-color: #002635;
}
</style>
